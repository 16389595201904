import { Box, Heading, LinkBox, Text } from '@chakra-ui/react';
import { Image } from 'components/shared';
import { NextChakraLinkOverlay } from 'components/wrappers';

interface Props {
  fontSize?: string;
  hasNoReadMore?: boolean;
  imageSize: string;
  _embedded?: any;
  link?: any;
  title?: any;
  acf?: any;
}

export const VerticalPostItem: React.FC<Props> = ({
  _embedded,
  link,
  title,
  fontSize = 'h4',
  acf
}) => {
  const featured_media = _embedded?.['wp:featuredmedia']?.[0];

  return (
    <LinkBox as='article' role={'group'} rounded='md' h='full'>
      <Box overflow='hidden' pos={'relative'}>
        <NextChakraLinkOverlay href={link}>
          <Image
            image={
              acf?.post_vertical_image
                ? acf?.post_vertical_image
                : featured_media
            }
            ratio={9 / 16}
            rounded='md'
            boxShadow={'xl'}
            objectFit='cover'
          />
        </NextChakraLinkOverlay>
        <Box
          width={'100%'}
          pos='absolute'
          //   zIndex={1}
          bottom='0'
          // backgroundColor='black'
          // boxShadow='10px 0 0 #f1efe2, 10px 0 0 #f1efe2, 10px 10px 0 #f1efe2, -10px 10px #f1efe2'
          // p='2'
          // opacity={'0.83'}
          textAlign='center'
          boxSizing='border-box'
          borderBottomRightRadius={'md'}
          borderBottomLeftRadius={'md'}
        >
          <Box bgGradient='linear(180deg,transparent, black, black)' p='4'>
            <Heading as='span' fontSize={fontSize} color={'black'}>
              {link && (
                <NextChakraLinkOverlay href={link}>
                  <Text
                    color='white'
                    lineHeight={'1.5'}
                    fontWeight={'600'}
                    as='span'
                    // noOfLines={2}

                    dangerouslySetInnerHTML={{ __html: title?.rendered }}
                  />
                </NextChakraLinkOverlay>
              )}
            </Heading>
          </Box>
        </Box>
      </Box>
    </LinkBox>
  );
};
