import { Box } from '@chakra-ui/react';
import { memo } from 'react';
import { VerticalPostItem } from 'components/shared/VerticalPostItem';

const VerticalCards = props => {
  const {
    attrs: { data },
    blockIndex,
    blockName,
    initialData,
    normalVertical
  } = props;

  if (initialData?.items && initialData?.items?.length === 0) return null;

  // const { items } = initialData;

  const shuffledArray = initialData?.items.sort((a, b) => 0.5 - Math.random());

  return (
    <Box
      display={'flex'}
      gap='4'
      overflowX={'scroll'}
      sx={{
        scrollbarColor: 'white white',
        '&::-webkit-scrollbar': {
          width: '3'
        },

        '&::-webkit-scrollbar-thumb': {
          bgColor: '#bdbaba',
          borderRadius: 'full',
          borderColor: 'white',
          borderWidth: '3',
          borderStyle: 'solid'
        }
      }}
    >
      {shuffledArray?.map((item, index) => {
        return (
          <Box
            key={index}
            flexGrow={0}
            flexShrink={0}
            flexBasis={{ base: '70%', md: normalVertical ? '23%' : '40%' }}
          >
            <VerticalPostItem
              key={index}
              {...item}
              imageSize={'(min-width: 62em) 19vw, 65vw'}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(VerticalCards);
